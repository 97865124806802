var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c("app-header", { attrs: { title: _vm.title, isShowBack: true } }),
      _c("div", { staticClass: "main-cont-box" }, [
        _c("div", { staticClass: "main-cont" }, [
          _vm.step == 2
            ? _c("div", { staticClass: "back" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function($event) {
                        _vm.back()
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "el-icon-arrow-left" }),
                    _c("span", { staticStyle: { color: "#30bac1" } }, [
                      _vm._v("返回上一步")
                    ])
                  ]
                )
              ])
            : _vm._e(),
          _vm.step == 1
            ? _c("div", { staticClass: "base-info" }, [
                _c("div", { staticClass: "info-title" }, [
                  _vm._v("第一步 填写基本信息")
                ]),
                _c(
                  "div",
                  { staticClass: "info-type" },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          position: "relative",
                          top: "-3px",
                          "padding-right": "10px"
                        }
                      },
                      [_vm._v("\n            选择注册主体类型\n          ")]
                    ),
                    _c(
                      "el-radio-group",
                      {
                        on: {
                          change: function($event) {
                            _vm.changeModel(_vm.type)
                          }
                        },
                        model: {
                          value: _vm.type,
                          callback: function($$v) {
                            _vm.type = $$v
                          },
                          expression: "type"
                        }
                      },
                      _vm._l(_vm.modeList, function(item, index) {
                        return _c(
                          "el-radio",
                          { key: index, attrs: { label: item.value } },
                          [_vm._v(_vm._s(item.label) + "\n            ")]
                        )
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.type == 1,
                        expression: "type == 1"
                      }
                    ]
                  },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "personalForm",
                        attrs: { model: _vm.personalForm, rules: _vm.rules }
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              "label-width": "170px",
                              label: "姓名",
                              prop: "name"
                            }
                          },
                          [
                            _c("el-input", {
                              staticStyle: {
                                width: "420px",
                                display: "inline-block"
                              },
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: _vm.personalForm.name,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.personalForm,
                                    "name",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "personalForm.name"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { "label-width": "170px", label: "性别" } },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.personalForm.sex,
                                  callback: function($$v) {
                                    _vm.$set(_vm.personalForm, "sex", $$v)
                                  },
                                  expression: "personalForm.sex"
                                }
                              },
                              _vm._l(_vm.genderList, function(item, index) {
                                return _c(
                                  "el-radio",
                                  { key: index, attrs: { label: item.value } },
                                  [
                                    _vm._v(
                                      _vm._s(item.label) + "\n                "
                                    )
                                  ]
                                )
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { "label-width": "170px", label: "证件类型" }
                          },
                          [
                            _c("el-input", {
                              staticStyle: {
                                width: "420px",
                                display: "inline-block"
                              },
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.personalForm.cardType,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.personalForm,
                                    "cardType",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "personalForm.cardType"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              "label-width": "170px",
                              label: "证件号码",
                              prop: "cardNo"
                            }
                          },
                          [
                            _c("el-input", {
                              staticStyle: {
                                width: "420px",
                                display: "inline-block"
                              },
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: _vm.personalForm.cardNo,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.personalForm,
                                    "cardNo",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "personalForm.cardNo"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "info-type" },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  position: "relative",
                                  top: "-3px",
                                  "padding-right": "10px",
                                  "padding-left": "12px"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                证件有效期类型\n              "
                                )
                              ]
                            ),
                            _c(
                              "el-radio-group",
                              {
                                on: {
                                  change: function($event) {
                                    _vm.changeValidityType(_vm.validityType)
                                  }
                                },
                                model: {
                                  value: _vm.validityType,
                                  callback: function($$v) {
                                    _vm.validityType = $$v
                                  },
                                  expression: "validityType"
                                }
                              },
                              _vm._l(_vm.validityTypeList, function(
                                item,
                                index
                              ) {
                                return _c(
                                  "el-radio",
                                  { key: index, attrs: { label: item.value } },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(item.label) +
                                        "\n                "
                                    )
                                  ]
                                )
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              "label-width": "170px",
                              label: "证件有效期",
                              prop: "validate"
                            }
                          },
                          [
                            _vm.validityType == 1
                              ? _c("el-date-picker", {
                                  staticStyle: {
                                    width: "420px",
                                    display: "inline-block"
                                  },
                                  attrs: {
                                    "value-format": "yyyy-MM-dd",
                                    format: "yyyy-MM-dd",
                                    type: "date",
                                    placeholder: "请选择日期",
                                    "picker-options": _vm.pickerOptions
                                  },
                                  model: {
                                    value: _vm.personalForm.validate,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.personalForm,
                                        "validate",
                                        $$v
                                      )
                                    },
                                    expression: "personalForm.validate"
                                  }
                                })
                              : _vm._e(),
                            _vm.validityType == 2
                              ? _c("el-input", {
                                  staticStyle: {
                                    width: "420px",
                                    display: "inline-block"
                                  },
                                  attrs: { disabled: "" },
                                  model: {
                                    value: _vm.personalForm.validate,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.personalForm,
                                        "validate",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "personalForm.validate"
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { "label-width": "170px", label: "联系地址" }
                          },
                          [
                            _c("el-input", {
                              staticStyle: {
                                width: "420px",
                                display: "inline-block"
                              },
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: _vm.personalForm.address,
                                callback: function($$v) {
                                  _vm.$set(_vm.personalForm, "address", $$v)
                                },
                                expression: "personalForm.address"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "submitBox" },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "submitBox-button",
                                attrs: {
                                  type: "primary",
                                  loading: _vm.loading
                                },
                                on: {
                                  click: function($event) {
                                    _vm.personalNext()
                                  }
                                }
                              },
                              [_vm._v("\n                下一步")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.type == 2,
                        expression: "type == 2"
                      }
                    ]
                  },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "enterpriseForm",
                        attrs: { model: _vm.enterpriseForm, rules: _vm.rules }
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              "label-width": "170px",
                              label: "企业名称",
                              prop: "enterpriseName"
                            }
                          },
                          [
                            _c("el-input", {
                              staticStyle: {
                                width: "420px",
                                display: "inline-block"
                              },
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: _vm.enterpriseForm.enterpriseName,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.enterpriseForm,
                                    "enterpriseName",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "enterpriseForm.enterpriseName"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              "label-width": "170px",
                              label: "统一信用代码",
                              prop: "creditCode"
                            }
                          },
                          [
                            _c("el-input", {
                              staticStyle: {
                                width: "420px",
                                display: "inline-block"
                              },
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: _vm.enterpriseForm.creditCode,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.enterpriseForm,
                                    "creditCode",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "enterpriseForm.creditCode"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              "label-width": "170px",
                              label: "企业法人姓名",
                              prop: "legalName"
                            }
                          },
                          [
                            _c("el-input", {
                              staticStyle: {
                                width: "420px",
                                display: "inline-block"
                              },
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: _vm.enterpriseForm.legalName,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.enterpriseForm,
                                    "legalName",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "enterpriseForm.legalName"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              "label-width": "170px",
                              label: "企业法人性别"
                            }
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.enterpriseForm.sex,
                                  callback: function($$v) {
                                    _vm.$set(_vm.enterpriseForm, "sex", $$v)
                                  },
                                  expression: "enterpriseForm.sex"
                                }
                              },
                              _vm._l(_vm.genderList, function(item, index) {
                                return _c(
                                  "el-radio",
                                  { key: index, attrs: { label: item.value } },
                                  [
                                    _vm._v(
                                      _vm._s(item.label) + "\n                "
                                    )
                                  ]
                                )
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              "label-width": "170px",
                              label: "企业法人证件类型"
                            }
                          },
                          [
                            _c("el-input", {
                              staticStyle: {
                                width: "420px",
                                display: "inline-block"
                              },
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.enterpriseForm.cardType,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.enterpriseForm,
                                    "cardType",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "enterpriseForm.cardType"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              "label-width": "170px",
                              label: "企业法人证件号码",
                              prop: "cardNo"
                            }
                          },
                          [
                            _c("el-input", {
                              staticStyle: {
                                width: "420px",
                                display: "inline-block"
                              },
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: _vm.enterpriseForm.cardNo,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.enterpriseForm,
                                    "cardNo",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "enterpriseForm.cardNo"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "info-type" },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  position: "relative",
                                  top: "-3px",
                                  "padding-right": "10px",
                                  "padding-left": "12px"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                证件有效期类型\n              "
                                )
                              ]
                            ),
                            _c(
                              "el-radio-group",
                              {
                                on: {
                                  change: function($event) {
                                    _vm.changeValidityType(_vm.validityType)
                                  }
                                },
                                model: {
                                  value: _vm.validityType,
                                  callback: function($$v) {
                                    _vm.validityType = $$v
                                  },
                                  expression: "validityType"
                                }
                              },
                              _vm._l(_vm.validityTypeList, function(
                                item,
                                index
                              ) {
                                return _c(
                                  "el-radio",
                                  { key: index, attrs: { label: item.value } },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(item.label) +
                                        "\n                "
                                    )
                                  ]
                                )
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              "label-width": "170px",
                              label: "企业法人证件有效期",
                              prop: "validate"
                            }
                          },
                          [
                            _vm.validityType == 1
                              ? _c("el-date-picker", {
                                  staticStyle: {
                                    width: "420px",
                                    display: "inline-block"
                                  },
                                  attrs: {
                                    "value-format": "yyyy-MM-dd",
                                    format: "yyyy-MM-dd",
                                    type: "date",
                                    placeholder: "请选择日期",
                                    "picker-options": _vm.pickerOptions
                                  },
                                  model: {
                                    value: _vm.enterpriseForm.validate,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.enterpriseForm,
                                        "validate",
                                        $$v
                                      )
                                    },
                                    expression: "enterpriseForm.validate"
                                  }
                                })
                              : _vm._e(),
                            _vm.validityType == 2
                              ? _c("el-input", {
                                  staticStyle: {
                                    width: "420px",
                                    display: "inline-block"
                                  },
                                  attrs: { disabled: "" },
                                  model: {
                                    value: _vm.enterpriseForm.validate,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.enterpriseForm,
                                        "validate",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "enterpriseForm.validate"
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "label-width": "168px",
                              label: "法人身份证国徽面",
                              prop: "identityFrontUrl"
                            }
                          },
                          [
                            _c(
                              "el-upload",
                              {
                                staticClass: "avatar-uploader",
                                class: {
                                  "one-upload-disabled":
                                    _vm.enterpriseForm.identityFrontUrl.length >
                                    0
                                },
                                attrs: {
                                  accept: "image/*",
                                  "list-type": "picture-card",
                                  action: _vm.picUrl,
                                  "file-list":
                                    _vm.enterpriseForm.identityFrontUrl,
                                  "on-preview": _vm.onPreviewByUploadImg,
                                  "before-remove": _vm.onBeforeRemoveByUploadImg(),
                                  "before-upload": _vm.onBeforeUpload,
                                  "on-success": _vm.onSuccessByUploadCardUrl1(),
                                  "on-remove": _vm.onHandleRemoveCardUrl1(),
                                  "on-exceed": _vm.onExceed
                                }
                              },
                              [
                                _vm.enterpriseForm.identityFrontUrl.length < 1
                                  ? _c("i", { staticClass: "el-icon-plus" })
                                  : _vm._e()
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "img-list" },
                              _vm._l(_vm.enterpriseForm.pictureList, function(
                                it
                              ) {
                                return _c("img", {
                                  key: it.src,
                                  attrs: { src: it.src },
                                  on: {
                                    click: function($event) {
                                      _vm.showBig(it.src)
                                    }
                                  }
                                })
                              }),
                              0
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "label-width": "168px",
                              label: "法人身份证人像面",
                              prop: "identityReverseUrl"
                            }
                          },
                          [
                            _c(
                              "el-upload",
                              {
                                staticClass: "avatar-uploader",
                                class: {
                                  "one-upload-disabled":
                                    _vm.enterpriseForm.identityReverseUrl
                                      .length > 0
                                },
                                attrs: {
                                  accept: "image/*",
                                  "list-type": "picture-card",
                                  action: _vm.picUrl,
                                  "file-list":
                                    _vm.enterpriseForm.identityReverseUrl,
                                  "on-preview": _vm.onPreviewByUploadImg,
                                  "before-remove": _vm.onBeforeRemoveByUploadImg(),
                                  "before-upload": _vm.onBeforeUpload,
                                  "on-success": _vm.onSuccessByUploadCardUrl2(),
                                  "on-remove": _vm.onHandleRemoveCardUrl2(),
                                  "on-exceed": _vm.onExceed
                                }
                              },
                              [
                                _vm.enterpriseForm.identityReverseUrl.length < 1
                                  ? _c("i", { staticClass: "el-icon-plus" })
                                  : _vm._e()
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "img-list" },
                              _vm._l(_vm.enterpriseForm.pictureList, function(
                                it
                              ) {
                                return _c("img", {
                                  key: it.src,
                                  attrs: { src: it.src },
                                  on: {
                                    click: function($event) {
                                      _vm.showBig(it.src)
                                    }
                                  }
                                })
                              }),
                              0
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "label-width": "168px",
                              label: "营业执照",
                              prop: "certificateUrl"
                            }
                          },
                          [
                            _c(
                              "el-upload",
                              {
                                staticClass: "avatar-uploader",
                                class: {
                                  "one-upload-disabled":
                                    _vm.enterpriseForm.certificateUrl.length > 0
                                },
                                attrs: {
                                  accept: "image/*",
                                  "list-type": "picture-card",
                                  action: _vm.picUrl,
                                  "file-list":
                                    _vm.enterpriseForm.certificateUrl,
                                  "on-preview": _vm.onPreviewByUploadImg,
                                  "before-remove": _vm.onBeforeRemoveByUploadImg(),
                                  "before-upload": _vm.onBeforeUpload,
                                  "on-success": _vm.onSuccessByUploadImgOnly(),
                                  "on-remove": _vm.onHandleRemove(),
                                  "on-exceed": _vm.onExceed
                                }
                              },
                              [
                                _vm.enterpriseForm.certificateUrl.length < 1
                                  ? _c("i", { staticClass: "el-icon-plus" })
                                  : _vm._e()
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "img-list" },
                              _vm._l(_vm.enterpriseForm.pictureList, function(
                                it
                              ) {
                                return _c("img", {
                                  key: it.src,
                                  attrs: { src: it.src },
                                  on: {
                                    click: function($event) {
                                      _vm.showBig(it.src)
                                    }
                                  }
                                })
                              }),
                              0
                            ),
                            _c("div", { staticClass: "img-upload-alt" }, [
                              _vm._v(
                                "\n                图片大小不得超过700KB，图片上不能有水印文字\n              "
                              )
                            ])
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { "label-width": "170px", label: "联系地址" }
                          },
                          [
                            _c("el-input", {
                              staticStyle: {
                                width: "420px",
                                display: "inline-block"
                              },
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: _vm.enterpriseForm.address,
                                callback: function($$v) {
                                  _vm.$set(_vm.enterpriseForm, "address", $$v)
                                },
                                expression: "enterpriseForm.address"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "margin-left": "126px",
                              "margin-bottom": "10px"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  color: "#f56c6c",
                                  padding: "0 4px"
                                }
                              },
                              [_vm._v("*")]
                            ),
                            _c("el-checkbox", {
                              model: {
                                value: _vm.noticeFlag,
                                callback: function($$v) {
                                  _vm.noticeFlag = $$v
                                },
                                expression: "noticeFlag"
                              }
                            }),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  color: "red",
                                  "font-size": "14px",
                                  "padding-left": "4px"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                企业用户必须绑定对公银行账户，否则无法提现\n              "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "submitBox" },
                          [
                            _c(
                              "el-button",
                              {
                                class: _vm.noticeFlag
                                  ? "submitBox-button"
                                  : "submitBox-button1",
                                attrs: { loading: _vm.loading },
                                on: {
                                  click: function($event) {
                                    _vm.enterpriseNext()
                                  }
                                }
                              },
                              [_vm._v("\n                下一步")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm.step == 2
            ? _c(
                "div",
                { staticClass: "base-info" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "myForm2",
                      attrs: { model: _vm.myForm2, rules: _vm.rules }
                    },
                    [
                      _c("div", { staticClass: "info-title" }, [
                        _vm._v(
                          "\n            第二步 填写手机号码（用于" +
                            _vm._s(
                              _vm.roleType == 1
                                ? "商家"
                                : _vm.roleType == 2
                                  ? "供应商"
                                  : "经销商"
                            ) +
                            "登录）\n          "
                        )
                      ]),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            "label-width": "170px",
                            label: "手机号码（登录账号）",
                            prop: "mobile"
                          }
                        },
                        [
                          _c("el-input", {
                            staticStyle: {
                              width: "420px",
                              display: "inline-block"
                            },
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.myForm2.mobile,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.myForm2,
                                  "mobile",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "myForm2.mobile"
                            }
                          }),
                          _vm.type == 1
                            ? _c("span", [
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !_vm.showTite,
                                        expression: "!showTite"
                                      }
                                    ],
                                    staticClass: "getCode",
                                    staticStyle: { color: "#666666" }
                                  },
                                  [_vm._v(_vm._s(this.count) + "s")]
                                ),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.showTite,
                                        expression: "showTite"
                                      }
                                    ],
                                    staticClass: "getCode",
                                    on: { click: _vm.getCode }
                                  },
                                  [_vm._v("获取验证码")]
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm.type == 1
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                "label-width": "170px",
                                label: "短信验证码",
                                prop: "verificationCode"
                              }
                            },
                            [
                              _c("el-input", {
                                staticStyle: {
                                  width: "420px",
                                  display: "inline-block"
                                },
                                attrs: { placeholder: "请输入" },
                                model: {
                                  value: _vm.myForm2.verificationCode,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.myForm2,
                                      "verificationCode",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "myForm2.verificationCode"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            "label-width": "170px",
                            label: "登录密码",
                            prop: "password"
                          }
                        },
                        [
                          _c("el-input", {
                            staticClass: "no-autofill-pwd",
                            staticStyle: {
                              width: "420px",
                              display: "inline-block"
                            },
                            attrs: { type: "text", placeholder: "请输入" },
                            model: {
                              value: _vm.myForm2.password,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.myForm2,
                                  "password",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "myForm2.password"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            "label-width": "170px",
                            label: "确认密码",
                            prop: "checkPassword"
                          }
                        },
                        [
                          _c("el-input", {
                            staticClass: "no-autofill-pwd",
                            staticStyle: {
                              width: "420px",
                              display: "inline-block"
                            },
                            attrs: { type: "text", placeholder: "请输入" },
                            model: {
                              value: _vm.myForm2.checkPassword,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.myForm2,
                                  "checkPassword",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "myForm2.checkPassword"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "submitBox" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "submitBox-button",
                              attrs: {
                                type: "primary",
                                loading: _vm.subLoading
                              },
                              on: {
                                click: function($event) {
                                  _vm.submit()
                                }
                              }
                            },
                            [_vm._v("提交\n            ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.step == 3
            ? _c("div", [
                _c("div", { staticClass: "success" }, [
                  _c("img", { attrs: { src: require("../assets/zvcg.png") } }),
                  _c("div", { staticClass: "success-text" }, [
                    _vm._v("提交成功！")
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "backLogin-button",
                      on: {
                        click: function($event) {
                          _vm.goToLogin1()
                        }
                      }
                    },
                    [_vm._v("\n            跳转到登录页\n          ")]
                  )
                ])
              ])
            : _vm._e()
        ])
      ]),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible, width: "50%" },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("img", {
            attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }