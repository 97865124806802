<template>
  <div class="main">
    <app-header :title="title" :isShowBack="true"></app-header>
    <div class="main-cont-box">
      <div class="main-cont">
        <div v-if="step == 2" class="back">
          <span @click="back()">
            <i class="el-icon-arrow-left" ></i>
            <span style="color: #30bac1;">返回上一步</span>
          </span>
        </div>
        <!-- 个人基本信息 -->
        <div class="base-info" v-if="step == 1">
          <div class="info-title">第一步 填写基本信息</div>
          <div class="info-type">
            <div style="position: relative; top: -3px; padding-right: 10px">
              选择注册主体类型
            </div>
            <el-radio-group v-model="type" @change="changeModel(type)">
              <el-radio
                v-for="(item, index) in modeList"
                :label="item.value"
                :key="index"
                >{{ item.label }}
              </el-radio>
            </el-radio-group>
          </div>
          <div v-show="type == 1">
            <el-form ref="personalForm" :model="personalForm" :rules="rules">
              <el-form-item label-width="170px" label="姓名" prop="name">
                <el-input
                  style="width: 420px; display: inline-block"
                  placeholder="请输入"
                  v-model.trim="personalForm.name"
                >
                </el-input>
              </el-form-item>
              <el-form-item label-width="170px" label="性别">
                <el-radio-group v-model="personalForm.sex">
                  <el-radio
                    v-for="(item, index) in genderList"
                    :label="item.value"
                    :key="index"
                    >{{ item.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label-width="170px" label="证件类型">
                <el-input
                  style="width: 420px; display: inline-block"
                  v-model.trim="personalForm.cardType"
                  disabled
                >
                </el-input>
              </el-form-item>
              <el-form-item label-width="170px" label="证件号码" prop="cardNo">
                <el-input
                  style="width: 420px; display: inline-block"
                  placeholder="请输入"
                  v-model.trim="personalForm.cardNo"
                >
                </el-input>
              </el-form-item>
              <div class="info-type">
                <div
                  style="
                    position: relative;
                    top: -3px;
                    padding-right: 10px;
                    padding-left: 12px;
                  "
                >
                  证件有效期类型
                </div>
                <el-radio-group
                  v-model="validityType"
                  @change="changeValidityType(validityType)"
                >
                  <el-radio
                    v-for="(item, index) in validityTypeList"
                    :label="item.value"
                    :key="index"
                  >
                    {{ item.label }}
                  </el-radio>
                </el-radio-group>
              </div>
              <el-form-item
                label-width="170px"
                label="证件有效期"
                prop="validate"
              >
                <el-date-picker
                  v-if="validityType == 1"
                  style="width: 420px; display: inline-block"
                  v-model="personalForm.validate"
                  value-format="yyyy-MM-dd"
                  format="yyyy-MM-dd"
                  type="date"
                  placeholder="请选择日期"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
                <el-input
                  v-if="validityType == 2"
                  style="width: 420px; display: inline-block"
                  disabled
                  v-model.trim="personalForm.validate"
                >
                </el-input>
              </el-form-item>
              <el-form-item label-width="170px" label="联系地址">
                <el-input
                  style="width: 420px; display: inline-block"
                  placeholder="请输入"
                  v-model="personalForm.address"
                >
                </el-input>
              </el-form-item>
              <div class="submitBox">
                <el-button
                  class="submitBox-button"
                  type="primary"
                  @click="personalNext()"
                  :loading="loading"
                >
                  下一步</el-button
                >
              </div>
            </el-form>
          </div>
          <div v-show="type == 2">
            <!-- 企业基本信息 -->
            <el-form
              ref="enterpriseForm"
              :model="enterpriseForm"
              :rules="rules"
            >
              <el-form-item
                label-width="170px"
                label="企业名称"
                prop="enterpriseName"
              >
                <el-input
                  style="width: 420px; display: inline-block"
                  placeholder="请输入"
                  v-model.trim="enterpriseForm.enterpriseName"
                >
                </el-input>
              </el-form-item>
              <el-form-item
                label-width="170px"
                label="统一信用代码"
                prop="creditCode"
              >
                <el-input
                  style="width: 420px; display: inline-block"
                  placeholder="请输入"
                  v-model.trim="enterpriseForm.creditCode"
                >
                </el-input>
              </el-form-item>
              <el-form-item
                label-width="170px"
                label="企业法人姓名"
                prop="legalName"
              >
                <el-input
                  style="width: 420px; display: inline-block"
                  placeholder="请输入"
                  v-model.trim="enterpriseForm.legalName"
                >
                </el-input>
              </el-form-item>
              <el-form-item label-width="170px" label="企业法人性别">
                <el-radio-group v-model="enterpriseForm.sex">
                  <el-radio
                    v-for="(item, index) in genderList"
                    :label="item.value"
                    :key="index"
                    >{{ item.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label-width="170px" label="企业法人证件类型">
                <el-input
                  style="width: 420px; display: inline-block"
                  v-model.trim="enterpriseForm.cardType"
                  disabled
                >
                </el-input>
              </el-form-item>
              <el-form-item
                label-width="170px"
                label="企业法人证件号码"
                prop="cardNo"
              >
                <el-input
                  style="width: 420px; display: inline-block"
                  placeholder="请输入"
                  v-model.trim="enterpriseForm.cardNo"
                >
                </el-input>
              </el-form-item>
              <div class="info-type">
                <div
                  style="
                    position: relative;
                    top: -3px;
                    padding-right: 10px;
                    padding-left: 12px;
                  "
                >
                  证件有效期类型
                </div>
                <el-radio-group
                  v-model="validityType"
                  @change="changeValidityType(validityType)"
                >
                  <el-radio
                    v-for="(item, index) in validityTypeList"
                    :label="item.value"
                    :key="index"
                  >
                    {{ item.label }}
                  </el-radio>
                </el-radio-group>
              </div>
              <el-form-item
                label-width="170px"
                label="企业法人证件有效期"
                prop="validate"
              >
                <el-date-picker
                  v-if="validityType == 1"
                  style="width: 420px; display: inline-block"
                  v-model="enterpriseForm.validate"
                  value-format="yyyy-MM-dd"
                  format="yyyy-MM-dd"
                  type="date"
                  placeholder="请选择日期"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
                <el-input
                  v-if="validityType == 2"
                  style="width: 420px; display: inline-block"
                  disabled
                  v-model.trim="enterpriseForm.validate"
                >
                </el-input>
              </el-form-item>
              <!-- <el-form-item label-width="170px" label="企业法人证件有效期" prop="validate">
                        <el-input style="width: 420px;display: inline-block;" placeholder="请输入"
                            v-model="enterpriseForm.validate">
                        </el-input>
                    </el-form-item> -->

              <el-form-item
                label-width="168px"
                label="法人身份证国徽面"
                style="width: 100%"
                prop="identityFrontUrl"
              >
                <el-upload
                  class="avatar-uploader"
                  accept="image/*"
                  list-type="picture-card"
                  :action="picUrl"
                  :file-list="enterpriseForm.identityFrontUrl"
                  :on-preview="onPreviewByUploadImg"
                  :before-remove="onBeforeRemoveByUploadImg()"
                  :before-upload="onBeforeUpload"
                  :on-success="onSuccessByUploadCardUrl1()"
                  :on-remove="onHandleRemoveCardUrl1()"
                  :on-exceed="onExceed"
                  :class="{
                    'one-upload-disabled':
                      enterpriseForm.identityFrontUrl.length > 0,
                  }"
                >
                  <i
                    v-if="enterpriseForm.identityFrontUrl.length < 1"
                    class="el-icon-plus"
                  ></i>
                </el-upload>
                <div class="img-list">
                  <img
                    v-for="it in enterpriseForm.pictureList"
                    :key="it.src"
                    :src="it.src"
                    @click="showBig(it.src)"
                  />
                </div>
              </el-form-item>
              <el-form-item
                label-width="168px"
                label="法人身份证人像面"
                style="width: 100%"
                prop="identityReverseUrl"
              >
                <el-upload
                  class="avatar-uploader"
                  accept="image/*"
                  list-type="picture-card"
                  :action="picUrl"
                  :file-list="enterpriseForm.identityReverseUrl"
                  :on-preview="onPreviewByUploadImg"
                  :before-remove="onBeforeRemoveByUploadImg()"
                  :before-upload="onBeforeUpload"
                  :on-success="onSuccessByUploadCardUrl2()"
                  :on-remove="onHandleRemoveCardUrl2()"
                  :on-exceed="onExceed"
                  :class="{
                    'one-upload-disabled':
                      enterpriseForm.identityReverseUrl.length > 0,
                  }"
                >
                  <i
                    v-if="enterpriseForm.identityReverseUrl.length < 1"
                    class="el-icon-plus"
                  ></i>
                </el-upload>
                <div class="img-list">
                  <img
                    v-for="it in enterpriseForm.pictureList"
                    :key="it.src"
                    :src="it.src"
                    @click="showBig(it.src)"
                  />
                </div>
              </el-form-item>
              <el-form-item
                label-width="168px"
                label="营业执照"
                style="width: 100%"
                prop="certificateUrl"
              >
                <el-upload
                  class="avatar-uploader"
                  accept="image/*"
                  list-type="picture-card"
                  :action="picUrl"
                  :file-list="enterpriseForm.certificateUrl"
                  :on-preview="onPreviewByUploadImg"
                  :before-remove="onBeforeRemoveByUploadImg()"
                  :before-upload="onBeforeUpload"
                  :on-success="onSuccessByUploadImgOnly()"
                  :on-remove="onHandleRemove()"
                  :on-exceed="onExceed"
                  :class="{
                    'one-upload-disabled':
                      enterpriseForm.certificateUrl.length > 0,
                  }"
                >
                  <i
                    v-if="enterpriseForm.certificateUrl.length < 1"
                    class="el-icon-plus"
                  ></i>
                </el-upload>
                <div class="img-list">
                  <img
                    v-for="it in enterpriseForm.pictureList"
                    :key="it.src"
                    :src="it.src"
                    @click="showBig(it.src)"
                  />
                </div>
                <div class="img-upload-alt">
                  图片大小不得超过700KB，图片上不能有水印文字
                </div>
              </el-form-item>
              <el-form-item label-width="170px" label="联系地址">
                <el-input
                  style="width: 420px; display: inline-block"
                  placeholder="请输入"
                  v-model="enterpriseForm.address"
                >
                </el-input>
              </el-form-item>
              <div
                style="display: flex; margin-left: 126px; margin-bottom: 10px"
              >
                <div style="color: #f56c6c; padding: 0 4px">*</div>
                <el-checkbox v-model="noticeFlag"></el-checkbox>
                <div style="color: red; font-size: 14px; padding-left: 4px">
                  企业用户必须绑定对公银行账户，否则无法提现
                </div>
              </div>
              <div class="submitBox">
                <el-button
                  :class="noticeFlag ? 'submitBox-button' : 'submitBox-button1'"
                  @click="enterpriseNext()"
                  :loading="loading"
                >
                  下一步</el-button
                >
              </div>
            </el-form>
          </div>
        </div>
        <div class="base-info" v-if="step == 2">
          <el-form ref="myForm2" :model="myForm2" :rules="rules">
            <div class="info-title">
              第二步 填写手机号码（用于{{
                roleType == 1 ? "商家" : (roleType == 2 ? "供应商" : "经销商")
              }}登录）
            </div>
            <el-form-item
              label-width="170px"
              label="手机号码（登录账号）"
              prop="mobile"
            >
              <el-input
                style="width: 420px; display: inline-block"
                placeholder="请输入"
                v-model.trim="myForm2.mobile"
              >
              </el-input>
              <span v-if="type == 1">
                <span v-show="!showTite" class="getCode" style="color: #666666"
                  >{{ this.count }}s</span
                >
                <span v-show="showTite" class="getCode" @click="getCode"
                  >获取验证码</span
                >
              </span>
            </el-form-item>
            <el-form-item
              v-if="type == 1"
              label-width="170px"
              label="短信验证码"
              prop="verificationCode"
            >
              <el-input
                style="width: 420px; display: inline-block"
                placeholder="请输入"
                v-model.trim="myForm2.verificationCode"
              >
              </el-input>
            </el-form-item>
            <el-form-item label-width="170px" label="登录密码" prop="password">
              <el-input
                type="text"
                style="width: 420px; display: inline-block"
                placeholder="请输入"
                v-model.trim="myForm2.password"
                class="no-autofill-pwd"
              >
              </el-input>
            </el-form-item>
            <el-form-item
              label-width="170px"
              label="确认密码"
              prop="checkPassword"
            >
              <el-input
                type="text"
                style="width: 420px; display: inline-block"
                placeholder="请输入"
                v-model.trim="myForm2.checkPassword"
                class="no-autofill-pwd"
              >
              </el-input>
            </el-form-item>
            <div class="submitBox">
              <el-button
                class="submitBox-button"
                type="primary"
                @click="submit()"
                :loading="subLoading"
                >提交
              </el-button>
            </div>
          </el-form>
        </div>
        <div v-if="step == 3">
          <div class="success">
            <img src="../assets/zvcg.png" />
            <div class="success-text">提交成功！</div>
            <div class="backLogin-button" @click="goToLogin1()">
              跳转到登录页
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible" width="50%">
      <img width="100%" :src="dialogImageUrl" alt />
    </el-dialog>
  </div>
</template>
<script>
import Vue from "vue";
let url = "/mall-service/merchant/v1/register";
let smsCodeUrl = "/mall-service/merchant/v1/sms/check";
let bindPhoneUrl = "/mall-service/merchant/v1/bind/mobile";
const TIME_COUNT = 60; //更改倒计时时间
let validID = (rule, value, callback) => {
  if (!value) {
    callback(new Error("请输入证件号码"));
  }
  if (!Vue.prototype.checkIdCard(value)) {
    callback(new Error("证件号码不正确"));
  } else {
    callback();
  }
};
let validMobile = (rule, value, callback) => {
  let reg = /^1[3-9](\d){9}$/;
  if (!reg.test(value)) {
    callback(new Error("手机号码格式不对"));
  } else {
    callback();
  }
};
export default {
  data() {
    let _this = this;
    return {
      title: "商家入驻",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7; //日期只能选择今天及以后的日期
        },
      },
      validityType: 1, //1-非长期 2-长期
      loading: false,
      subLoading: false,
      dialogVisible: false,
      dialogImageUrl: "",
      picUrl: _this.UPLOAD_URL + "/user-service/weChat/uploadPic",
      bizUserId: "",
      step: 1,
      count: 0,
      timer: null,
      showTite: true,
      type: 1, //1--个人 2--企业
      roleType: 1, //入驻类型 1-商家入驻 2-供应商入驻 3-经销商入驻
      noticeFlag: false,
      personalForm: {
        // 第二步
        name: "",
        sex: 1,
        cardType: "身份证",
        cardNo: "",
        validate: "",
        address: "",
      },
      enterpriseForm: {
        certificateUrl: [],
        identityFrontUrl: [],
        identityReverseUrl: [],
        enterpriseName: "",
        creditCode: "",
        sex: 1,
        cardType: "身份证",
        cardNo: "",
        validate: "",
        address: "",
        legalName: "",
      },
      enterpriseDto: {},
      personalDto: {},
      myForm2: {
        // 第二步
        mobile: "",
        verificationCode: "",
        password: "",
        checkPassword: "",
      },
      modeList: [
        {
          value: 1,
          label: "个人",
        },
        {
          value: 2,
          label: "企业",
        },
      ],
      validityTypeList: [
        {
          value: 1,
          label: "非长期",
        },
        {
          value: 2,
          label: "长期",
        },
      ],
      genderList: [
        {
          value: 1,
          label: "男",
        },
        {
          value: 2,
          label: "女",
        },
      ],
      rules: {
        name: [
          {
            required: true,
            message: "请输入姓名",
            trigger: ["change", "blur"],
          },
        ],
        cardNo: [
          {
            required: true,
            // validator: validID,
            message: "请输入证件号码",
            trigger: ["change", "blur"],
          },
        ],
        validate: [
          {
            required: true,
            message: "请选择证件有效期",
            trigger: ["change", "blur"],
          },
        ],
        mobile: [
          {
            required: true,
            validator: validMobile,
            trigger: ["change", "blur"],
          },
        ],
        verificationCode: [
          {
            required: true,
            message: "请输入短信验证码",
            trigger: ["change", "blur"],
          },
        ],
        password: [
          {
            required: true,
            message: "请输入登录密码",
            trigger: ["change", "blur"],
          },
        ],
        checkPassword: [
          {
            required: true,
            message: "请输入确认密码",
            trigger: ["change", "blur"],
          },
        ],
        enterpriseName: [
          {
            required: true,
            message: "请输入企业名称",
            trigger: ["change", "blur"],
          },
        ],
        legalName: [
          {
            required: true,
            message: "请输入企业法人姓名",
            trigger: ["change", "blur"],
          },
        ],
        creditCode: [
          {
            required: true,
            message: "请输入统一信用代码",
            trigger: ["change", "blur"],
          },
        ],
        certificateUrl: [
          {
            required: true,
            message: "请上传营业执照",
            trigger: ["change", "blur"],
          },
        ],
        identityFrontUrl: [
          {
            required: true,
            message: "请上传身份证国徽面",
            trigger: ["change", "blur"],
          },
        ],
        identityReverseUrl: [
          {
            required: true,
            message: "请上传身份证人像面",
            trigger: ["change", "blur"],
          },
        ],
      },
    };
  },
  created() {
  },
  activated() {
    console.log('aaaaaa')
    this.roleType = this.$route.params.type;
    this.type = 1;//初始化
    this.validityType = 1;//初始化
    this.step = 1;
    if(this.roleType) {
      this.title = ['商家','供应商','经销商'][Number(this.roleType) - 1] + '入驻';
    }
    this.personalForm = {
      // 第一步
      name: "",
      sex: 1,
      cardType: "身份证",
      cardNo: "",
      validate: "",
      address: "",
      type: 1,
    };
    this.noticeFlag = false;
    this.enterpriseForm = {
      certificateUrl: [],
      identityFrontUrl: [],
      identityReverseUrl: [],
      name: "",
      creditCode: "",
      enterpriseName: "",
      sex: 1,
      cardType: "身份证",
      cardNo: "",
      validate: "",
      address: "",
      legalName: "",
    };
    this.myForm2 = {
      // 第二步
      mobile: "",
      verificationCode: "",
      password: "",
      checkPassword: "",
    };
    this.$nextTick(() => {
      this.$refs["personalForm"].resetFields();
      this.$refs["enterpriseForm"].resetFields();
    });
  },
  methods: {
    changeModel(val) {
      this.type = val;
      this.validityType = 1;
      if (val == 1) {
        this.$nextTick(() => {
          this.$refs["enterpriseForm"].resetFields();
        });
        this.noticeFlag = false;
        this.personalForm.validate = "";
      } else if (val == 2) {
        this.$nextTick(() => {
          this.$refs["personalForm"].resetFields();
        });
        this.enterpriseForm.validate = "";
      }
    },
    changeValidityType(val) {
      this.validityType = val;
      if (this.type == 1) {
        if (val == 1) {
          this.personalForm.validate = "";
        } else {
          this.personalForm.validate = "长期";
        }
      } else {
        if (val == 1) {
          this.enterpriseForm.validate = "";
        } else {
          this.enterpriseForm.validate = "长期";
        }
      }
    },
    back() {
      this.step = 1;
    },
    personalNext() {
      this.loading = true;
      this.$refs["personalForm"].validate((valid) => {
        if (valid) {
          let dto = {
            name: this.personalForm.name,
            sex: this.personalForm.sex,
            cardType: 1, //1:身份证,2护照'
            cardNo: this.personalForm.cardNo,
            validate: this.personalForm.validate,
            address: this.personalForm.address,
            type: this.type,
            roleType: this.roleType,
          };
          this.personalDto = dto;
          this.post(url, dto, {
            isUseResponse: true,
          })
            .then((res) => {
              if (res.data.code == 0) {
                this.step = 2;
                this.bizUserId = res.data.data;
                console.log("this.bizUserId-----------:", this.bizUserId);
              }
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          this.loading = false;
        }
      });
    },
    enterpriseNext() {
      if (!this.noticeFlag) {
        return;
      }
      console.log("下一步");
      this.loading = true;
      this.$refs["enterpriseForm"].validate((valid) => {
        if (valid) {
          let dto = {
            name: this.enterpriseForm.enterpriseName,
            creditCode: this.enterpriseForm.creditCode,
            legalName: this.enterpriseForm.legalName,
            sex: this.enterpriseForm.sex,
            cardType: 1, //1:身份证,2护照'
            cardNo: this.enterpriseForm.cardNo,
            validate: this.enterpriseForm.validate,
            identityFrontUrl: this.enterpriseForm.identityReverseUrl[0].url, //人像面
            identityReverseUrl: this.enterpriseForm.identityFrontUrl[0].url, //国徽面
            certificateUrl: this.enterpriseForm.certificateUrl[0].url,
            address: this.enterpriseForm.address,
            type: this.type,
            roleType: this.roleType,
          };
          this.enterpriseDto = dto;
          this.post(url, dto, {
            isUseResponse: true,
          })
            .then((res) => {
              if (res.data.code == 0) {
                this.step = 2;
                this.bizUserId = res.data.data;
                console.log("red-----------:", res);
                console.log("this.bizUserId-----------:", this.bizUserId);
              }
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          this.loading = false;
        }
      });
    },
    submit() {
      this.subLoading = true;
      this.$refs["myForm2"].validate((valid) => {
        if (valid) {
          let dto = {
            bizUserId: this.bizUserId,
            phone: this.myForm2.mobile,
            verificationCode: this.myForm2.verificationCode,
            password: this.myForm2.password,
            checkPassword: this.myForm2.checkPassword,
          };
          if (this.type == 1) {
            //个人
            Object.assign(dto, this.personalDto);
          } else {
            //企业
            Object.assign(dto, this.enterpriseDto);
          }
          this.post(bindPhoneUrl, dto, {
            isUseResponse: true,
          })
            .then((res) => {
              if (res.data.code == 0) {
                this.step = 3;
              }
              this.subLoading = false;
            })
            .catch(() => {
              this.subLoading = false;
            });
        } else {
          this.subLoading = false;
        }
      });
    },
    goToLogin1() {
      this.$router.push("/login");
    },
    getCode() {
      console.log("this.myForm2.mobile:", this.myForm2.mobile);
      if (!this.myForm2.mobile) {
        this.$message({
          showClose: true,
          message: "手机号码不能为空！",
          type: "warning",
        });
        return;
      }
      if (!this.showTite) {
        return;
      }
      let dto = {
        bizUserId: this.bizUserId,
        phone: this.myForm2.mobile,
        verificationCodeType: 9,
      };
      if (!this.timer) {
        this.post(smsCodeUrl, dto)
          .then((res) => {
            this.count = TIME_COUNT;
            this.showTite = false;
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--;
              } else {
                this.showTite = true;
                clearInterval(this.timer); // 清除定时器
                this.timer = null;
              }
            }, 1000);
            this.$message({
              message: "发送成功！",
              type: "success",
            });
          })
          .catch((err) => {});
      }
      console.log("获取验证码");
    },
    //配合图片上传确认item
    onPreviewByUploadImg(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    //表单图片单张上传
    onSuccessByUploadImgOnly(item) {
      return (response, file, fileList) => {
        this.enterpriseForm.certificateUrl.push({
          url: response.data.picPath,
        });
        this.$refs.enterpriseForm.clearValidate(["certificateUrl"]);
      };
    },
    // 图片删除
    onHandleRemove() {
      return (file, fileList) => {
        this.enterpriseForm.certificateUrl = fileList;
      };
    },

    //表单图片单张上传
    onSuccessByUploadCardUrl1(item) {
      return (response, file, fileList) => {
        this.enterpriseForm.identityFrontUrl.push({
          url: response.data.picPath,
        });
        this.$refs.enterpriseForm.clearValidate(["identityFrontUrl"]);
        console.log(
          "this.enterpriseForm.identityFrontUrl:",
          this.enterpriseForm.identityFrontUrl
        );
      };
    },
    // 图片删除
    onHandleRemoveCardUrl1() {
      return (file, fileList) => {
        this.enterpriseForm.identityFrontUrl = fileList;
      };
    },

    //表单图片单张上传
    onSuccessByUploadCardUrl2(item) {
      return (response, file, fileList) => {
        this.enterpriseForm.identityReverseUrl.push({
          url: response.data.picPath,
        });
        this.$refs.enterpriseForm.clearValidate(["identityReverseUrl"]);
      };
    },
    // 图片删除
    onHandleRemoveCardUrl2() {
      return (file, fileList) => {
        this.enterpriseForm.identityReverseUrl = fileList;
      };
    },

    //文件上传数量超过限制
    onExceed() {
      this.$message({
        message: "文件上传数量超过限制，请删除后重新上传",
        type: "warning",
      });
    },
    //图片上传的图片删除
    onBeforeRemoveByUploadImg() {
      return (file, fileList) => {
        let isSmall = true;
        if (file.size) {
          isSmall = file.size / 1024 < 700;
        }
        if (!isSmall) {
          return true;
        } else {
          return new Promise((resolve, reject) => {
            let _this = this;
            let is = true;
            if (is) {
              this.$confirm("确认删除此图片吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
                .then(() => {
                  console.log("fileList:", fileList);
                  resolve();

                  return true;
                })
                .catch(function () {
                  reject();
                });
            } else {
              resolve();
            }
          });
        }
      };
    },
    //放大图片
    showBig(val) {
      console.log("val:", val);
      this.dialogImageUrl = val;
      this.dialogVisible = true;
    },
    //上传图片大小
    onBeforeUpload(file) {
      const fileList = file.size / 1024 < 700;
      if (!fileList) {
        this.$message.error("上传的图片不能超过700KB");
        return false;
      } else {
        return fileList;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  padding: 0 50px;
  padding-top: 10px;
}

.main-cont-box {
    position: relative;
    margin-top: 60px;
}

.main-cont {
    position: absolute;
    width: 735px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.main-tip {
  border-bottom: 1px solid #333333;
  padding: 20px 0;
}

.info-type {
  display: flex;
  font-size: 14px;
  color: #606266;
  position: relative;
  top: -3px;
  padding-right: 6px;
  margin-left: 50px;
  margin-bottom: 20px;
}

.back {
  margin-top: 10px;
  cursor: pointer;
}

.back-button {
  margin-right: 6px;
}

.base-info {
  padding-left: 50px;
}

.info-title {
  font-weight: 600;
  color: #333333;
  margin-bottom: 22px;
  padding-top: 10px;
}

.row {
  margin-bottom: 10px;
}

.row-title {
  display: inline-block;
  padding-right: 20px;
  width: 200px;
  text-align: right;
}

.row-value {
  width: 300px;
}

.submit {
  margin-left: 242px;
}

.submit-button {
  margin-top: 26px;
  margin-bottom: 20px;
  width: 200px;
}

.margin-top {
  margin-top: 30px;
}

.submitBox {
  margin-left: 202px;
  padding-bottom: 20px;
}

.submitBox-button {
  width: 200px;
  height: 37px;
  background-color: #30bac1;
  border-color: #30bac1;
  color: #ffffff;
}

.submitBox-button1 {
  width: 200px;
  height: 37px;
  background-color: #82848a;
  border-color: #82848a;
  color: #ffffff;
}

.success {
  text-align: center;
  margin-top: 50px;
}

.backLogin-button {
  display: inline-block;
  margin-top: 30px;
  border: 1px solid #30bac1;
  width: 200px;
  height: 40px;
  line-height: 40px;
  border-radius: 10px;
  cursor: pointer;
}

.success-text {
  padding: 20px 0;
}

.getCode {
  color: #30bac1;
  position: relative;
  left: -87px;
  cursor: pointer;
}

.no-autofill-pwd {
  /deep/ .el-input__inner {
    -webkit-text-security: none !important;
  }
}

.img-upload-alt {
  font-size: 12px;
  color: #999;
}
</style>